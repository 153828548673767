<template>
  <div class="friendCircle page">
    <div class="p-footer h-end"
         style="position: relative;">
      <button class="btn auto fill ml-3"
              @click="toIndex()">返回首页
      </button>
    </div>
    <div class="s1 p-card fs-16 m-3">
      <div class="h-between p-3 text">
        <div class="flex">
          <p>自动跟发官方朋友圈</p>
          <van-switch :modelValue="isOpenPush" size="20px" @change="statusChange"/>
        </div>
        <div class="fs-12 color-gray ">自动替换朋友圈的链接为店主链接</div>
        <div class="fs-12 color-gray mt-1">新绑定的机器人，在登陆24小时内朋友圈可能发送不成功。</div>
      </div>
    </div>
    <div class="s2 m-3">
      <p class="title">朋友圈列表</p>
      <div class="p-card fs-16">
        <div class="b2">
          <van-tabs v-model="tabVal">
            <van-tab :title="`待发送（${waitList.length}）`"
                     style="overflow-y: auto;">
              <van-list v-if="waitList.length>0"
                        v-model:loading="waitLoading"
                        :finished="true"
                        finished-text="已经到底啦">
                <div v-for="(item, index) in waitList"
                     :key="item.msgId"
                     class="item">
                  <img class="headImg" :src="robotInfo.headImgUrl" alt=""/>
                  <div class="text">
                    <div class="name">
                      <p>{{ robotInfo.nickName }}</p>
                      <img class="group-editor mr-2" src="../../assets/icon_edit.png" alt="编辑"
                           @click="goEdit(item)">
                      <div class="flex">
                        <span class="follow-status color-gray" v-if="!item.isPush">未跟发</span>
                        <span class="follow-status color-primary" v-else>跟发中</span>
                        <van-switch
                            class="ml-1"
                            :modelValue="item.isPush"
                            size="14px"
                            @change="setWaitStatus($event, item)"/>
                      </div>
                    </div>
                    <show-explain :content="item.content"/>
                    <van-grid v-if="item.picUrl.length > 0"
                              :column-num="3"
                              :border="false">
                      <van-grid-item v-for="(src, index) in item.picUrl"
                                     :key="index">
                        <van-image :src="src"
                                   fit="scale-down"
                                   @click="showImage(item.picUrl, index)"/>
                      </van-grid-item>
                    </van-grid>
                    <div class="time">
                      <p>发送时间：{{ item.pushTime }}</p>
                      <div class="btn">来自官方
                      </div>
                    </div>
                  </div>
                </div>
              </van-list>
              <no-data v-else :showButton="false" height="4.2rem"
                       title="暂未发布朋友圈"/>
            </van-tab>
            <van-tab :title="`已发送（${completeList.length}）`"
                     style="overflow-y: auto;">
              <van-list v-if="completeList.length>0"
                        v-model:loading="completeLoading"
                        :finished="true"
                        finished-text="没有更多了">
                <div v-for="(item, index) in completeList"
                     :key="item.msgId"
                     class="item">
                  <img :src="robotInfo.headImgUrl" class="headImg" alt=""/>
                  <div class="text">
                    <div class="name flex">
                      <p>{{ robotInfo.nickName }}</p>
                      <div class="flex-right " :class=" item.isPush  ? 'color-gray' : 'color-primary'">
                        {{ item.stateName }}
                      </div>
                    </div>
                    <show-explain :content="item.content"/>
                    <van-grid v-if="item.picUrl.length > 0"
                              :column-num="3"
                              :border="false">
                      <van-grid-item v-for="(src, index) in item.picUrl"
                                     :key="index">
                        <van-image :src="src"
                                   fit="scale-down"
                                   @click="showImage(item.picUrl, index)"/>
                      </van-grid-item>
                    </van-grid>
                    <div class="time">
                      <p>发送时间：{{ item.pushTime }}</p>
                      <div class="btn"
                           @click="handleRemove(item, index)">删除
                      </div>
                    </div>
                  </div>
                </div>
              </van-list>
              <no-data v-else no-button :showButton="false" height="4.2rem"
                       title="暂未发布朋友圈"/>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import msgBox from '@/components/msg-box/index.vue'
import AplusRecord from '../../utils/AplusRecord'
import {ImagePreview, Notify, Toast} from 'vant'
import {mapGetters} from 'vuex'
import {
  ApiDelPushMsgRecord,
  ApiGetPushMsg,
  ApiGetPushMsgRecord,
  ApiIsOpenPush,
  ApiSetNotPushMsg,
  ApiSetOpenPush
} from '../../api/FriendCircle'
import showExplain from './ShowExplain'

export default {
  name: 'WelcomeWords',
  components: {
    msgBox,
    showExplain
  },
  data() {
    return {
      isOpenPush: false,
      waitList: [],
      waitLoading: false,
      completeList: [],
      completeListTotal: 0,
      completeLoading: false,
      tabVal: 1
    }
  },
  computed: {
    ...mapGetters(['robotInfo'])
  },
  created() {

  },
  async mounted() {
    await this.GetUserFollowSet()
    await this.getWaitList()
    await this.getCompleteList()
  },
  updated() {
  },
  methods: {
    showImage(images, index) {
      ImagePreview({
        images: images, // 预览图片的那个数组
        showIndex: true,
        loop: false,
        startPosition: index // 指明预览第几张图
      })
    },
    // 获取跟发状态
    async GetUserFollowSet() {
      try {
        const res = await ApiIsOpenPush()
        this.isOpenPush = res
      } catch (e) {
        console.log(e)
      }
    },
    // 待发送列表
    async getWaitList() {
      const toast = Toast.loading({
        message: '数据加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      this.waitLoading = true
      try {
        const res = await ApiGetPushMsg()
        this.waitList = (res && res.map(i => {
          i.picUrl = i.picUrl ? i.picUrl.split(',') : []
          return i
        })) || []
        this.waitLoading = false
        toast.clear()
      } catch (e) {
        this.waitLoading = false
        toast.clear()
        console.log(e)
      }

    },

    async getCompleteList() {
      const toast = Toast.loading({
        message: '数据加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      this.completeLoading = true
      try {
        const res = await ApiGetPushMsgRecord()
        this.completeLoading = false
        const stateType = {
          1: '发送失败',
          2: '发送中',
          3: '发送成功'
        }
        this.completeList = (res && res.map(i => {
          i.stateName = stateType[i.state]
          i.control = true
          i.picUrl = i.picUrl ? i.picUrl.split(',') : []
          return i
        })) || []
        toast.clear()
      } catch (e) {
        this.completeLoading = true
        toast.clear()
        console.log(e)
      }
    },
    // 修改跟发状态
    async statusChange(val) {
      try {
        await ApiSetOpenPush(val)
        this.$Toast('切换成功')
        if (!val) {
          await this.getWaitList()
        }
        this.isOpenPush = val
        AplusRecord('zidonggenquan_', val)
      } catch (e) {
        console.log(e)
      }
    },
    goEdit(val) {
      if (new Date(val.pushTime).getTime() < (Date.now() - 5 * 60 * 1000)) {
        Notify({
          type: 'warning',
          message: '朋友圈提前5分钟进行排队发送，不能编辑了哦！',
          duration: 1000
        })
        return
      }
      this.$router.push({
        path: '/friendCircleEdit',
        query: {
          msgId: val.msgId
        }
      })
    },
    // 设置跟发状态
    async setWaitStatus(val, item) {
      try {
        await ApiSetNotPushMsg({
          msgId: item.msgId,
          msgCode: item.msgCode,
          isPush: val
        })
        item.isPush = val
        this.$Toast('设置成功')
        await this.GetUserFollowSet()
      } catch (e) {
        console.log(e)
      }
    },
    handleRemove(item, index) {
      this.$Dialog.confirm({
        title: '确认删除这条朋友圈？'
      })
          .then(async () => {
            try {
              await ApiDelPushMsgRecord(item.id)
              this.completeList.splice(index, 1)
              this.$Toast('删除成功')
            } catch (e) {
              console.log(e)
            }
          })
    },
    toIndex() {
      const _this = this
      _this.$router.replace('/index')
    }
  }
}
</script>

<style scoped lang="scss">
.two-flow {
  -webkit-line-clamp: initial;
}

.friendCircle {
  :deep(.van-list) {
    height: 420px;
  }

  .show-all {
    -webkit-line-clamp: initial;
  }
}

.group-editor {
  width: 20px;
  height: 20px;
}

.follow-status {
  font-size: 12px;
  line-height: 12px;
}

.page {

  .s1 {
    .text {
      .flex {
        p {
          flex: 1;
          margin: 0;
          color: #333;
        }
      }

      span {
        font-size: px(12);
        color: #888;
      }
    }
  }


  .title {
    font-size: px(14);
    color: #888;
    margin: 0 0 px(5) 0;
  }

  .s2 {
    .b2 {
      //padding-bottom: px(20);
    }

    .item {
      margin-left: px(10);
      padding: px(15) px(10) px(15) 0;
      border-bottom: px(1) #f1f1f1 dashed;
      font-size: px(14);
      display: flex;

      .headImg {
        width: px(44);
        height: px(44);
        overflow: hidden;
        border-radius: 50%;
        margin-right: px(6);

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .text {
        flex: 1;

        .name {
          display: flex;
          margin-bottom: px(5);
          align-items: center;

          p {
            flex: 1;
            margin: 0;
            color: #35557c;
            font-size: px(14);
            line-height: px(20);
          }

          .btn {
            color: #35557c;
            font-size: px(12);
            margin-right: px(5);

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .con {
          margin-bottom: px(15);
        }

        .link {
          display: flex;
          align-items: center;
          background-color: #f6f6f6;
          border-radius: px(4);
          padding: px(7);
          margin-bottom: px(15);

          .linkImg {
            width: px(44);
            height: px(44);
            margin-right: px(5);
            border-radius: px(2);
            overflow: hidden;

            .van-image {
              width: 100%;
              height: 100%;
            }

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .linkTitle {
            flex: 1;
          }
        }

        .time {
          display: flex;
          align-items: center;

          p {
            font-size: px(12);
            color: #888;
            flex: 1;
            margin: 0;
          }

          .btn {
            color: #35557c;
            font-size: px(12);
          }
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .van-grid {
    margin: px(10) 0;
  }
}
</style>
<style lang="scss">
.friendCircle {
  //padding-bottom: px(60);

  .van-grid-item__content {
    padding: 0;
  }

  .van-grid-item__content {
    position: relative;
    padding: px(4);

    .van-image {
      // width: px(80);
      // height: px(80);

      width: px(70);
      height: px(70);
    }
  }

  .mt-70 {
    margin-top: 70px !important;
  }

  .i_remind {
    width: px(16);
    height: px(16);
  }


}

@media (max-width: 370px) {
  .friendCircle {
    .van-grid-item__content {
      .van-image {
        width: px(60);
        height: px(60);
      }
    }
  }
}
</style>
