export default function AplusRecord(type, state) {
  const { aplus_queue } = window
  let stateName = ''
  if(typeof state === 'number'){
    stateName = state === 1 ? '开' : '关'
  }else if(typeof state === 'boolean'){
    stateName = state ? '开': '关'
  }
  aplus_queue.push({
    action: 'aplus.record',
    arguments: [type, 'CLK', {
      param1: stateName,
      param2: 0,
    }]
  })
}
